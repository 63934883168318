<template>
  <div>
    <van-nav-bar
      title="设置中心"
      left-arrow
      @click-left="onClickLeft"
    />
<!--    <van-cell title="头像" label="点击右侧头像修改">-->
<!--      &lt;!&ndash; 使用 right-icon 插槽来自定义右侧图标 &ndash;&gt;-->
<!--      <template #right-icon>-->
<!--        <van-uploader>-->
<!--          <van-image :src="avatar" alt="" fit="cover" round />-->
<!--        </van-uploader>-->
<!--      </template>-->
<!--    </van-cell>-->
    <van-cell-group>
      <van-cell title="姓名" :value="username" />
      <van-cell title="手机号" :value="mobile" />
      <van-cell title="修改登录密码" is-link to="/user/setPwd"/>
      <van-cell title="修改支付密码" is-link to="/user/setPayPwd"/>
    </van-cell-group>
    <div class="margin-20">
      <van-button type="danger" size="large" @click="logout()">退出登录</van-button>
    </div>
  </div>
</template>

<script>
import { upload, updateUser, getUserInfo } from '@/utils/api.js'
export default {
  name: 'setting',
  data () {
    return {
      username: '',
      mobile: '',
      avatar: ''
    }
  },
  created () {
    this.getUser()
  },
  methods: {
    getUser () {
      getUserInfo().then(res => {
        if (res.code === 200) {
          this.username = res.data.user.username
          this.mobile = res.data.user.mobile
          this.avatar = res.data.user.avatar
        }
      })
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    afterRead (file) {
      const formData = new FormData()
      formData.append('file', file.file)
      upload(formData).then(res => {
        if (res.code === 200) {
          this.avatar = res.data
          this.updateUser()
        } else {
          this.$toast('上传失败')
        }
      })
    },
    updateUser () {
      const param = { avatar: this.avatar }
      updateUser(param).then(res => {
        if (res.code === 200) {
          this.$toast('上传成功')
        } else {
          this.$toast('更新失败')
        }
      })
    },
    logout () {
      this.$dialog.confirm({
        title: '系统提示',
        message: '确定要退出登录吗？',
      }).then(() => {
        this.$store.dispatch('user/logout')
        this.$router.push('/home')
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
  .van-image{
    display: block;
    height: 3.5rem;
    width: 3.5rem;
  }
</style>
